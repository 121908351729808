import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ICustomerGroup extends IBaseEntity {
    editable: any;
    id?: number;
    name?: string;
    description?: string;
}
export class CustomerGroup extends BaseEntity implements ICustomerGroup {
    constructor(customerGroup?) {
        super();
        if (customerGroup) {
            this.id = customerGroup.id;
            this.name = customerGroup.name;
            this.description = customerGroup.description;
        } else {
        }
    }
    get discriminator(): any {
        return this.name;
    }
    public id?: number;
    public name?: string;
    public description?: string;

    editable: any;
    clone(): CustomerGroup {
        return new CustomerGroup(this);
    }
}
