export const SCHEDULE_SALE_ORDER_AFTER_N_DAYS = 'scheduleSaleOrderAfterNDays';
export const ENABLE_DUPLICATION_OF_LOAD_ORDERS = 'enableDuplicationOfLoadOrders';
export const ENABLE_STOP_ALLOCATION_TO_ALL_ROUTES = 'enableOptimisationAcrossRoutes';
export const SKIP_PACK_ENABLED = 'skipPackEnabled';
export const ALLOW_SERVICE_ORDERS = 'allowServiceOrders';
export const CREATE_CUSTOMER_IN_SALE_ORDER = 'createCustomerInSaleOrder';
export const PICK_PACK_LOAD_BUTTON = 'pickPackLoadButton';
export const LOCK_UNLOCK_ROUTE_PLANNER = 'lockUnlockRouteRoutePlanner';
export const SHOW_ZERO_STOCK_PRODUCTS_SALE_ORDER = 'showZeroStockProductsSaleOrder';
export const ENABLE_PRODUCT_WHITELIST_BLACKLIST = 'enableProductWhitelistBlacklist';
export const ENABLE_SCHEDULED_VAN_SALE_VISITS = 'enableScheduledVanSaleVisits';
export const ENABLE_STANDING_ORDERS = 'enableStandingOrders';
export const STANDING_ORDER_DAYS_IN_ADVANCE = 'standingOrderDaysInAdvance';
export const ENABLE_PRICE_LISTS = 'enablePriceLists';
export const ENABLE_ROUTE_OPTIMIZATION = 'enableRouteOptimization';
export const ENABLE_ADVANCED_ANALYTICS = 'enableAdvancedAnalytics';
export const ENABLE_DEPOSIT_ITEMS = 'enableDepositItems';
export const ENABLE_DELIVERY_POINT_SETTINGS = 'enableDeliveryPointSettings';
export const ENABLE_DRIVER_MESSAGES = 'enableDriverMessages';
export const ENABLE_PICK_AND_PACK = 'enablePickAndPack';
export const ENABLE_ROUTE_PLANNER = 'enableRoutePlanner';
export const ENABLE_ROUTE_MONITOR = 'enableRouteMonitor';
export const NUMBER_PREVIOUS_ORDERS_SHOWN_ON_ORDER_PAGE = 'noOfPreviousOrdersShownOnOrderPage';
export const ENABLE_PRICE_EDIT_FOR_PURCHASE_ORDER_ITEM = 'enablePriceEditForPurchaseItem';
export const ENABLE_PARTNER_MODE = 'enablePartnerMode';
export const ENABLE_PAYMENT_THROUGH_PARTNER_CREDIT = 'enablePaymentThroughPartnerCredit';
export const TAX_SYSTEM = 'taxSystem';
export const ENABLE_COMMISSIONS = 'enableCommissions';
export const ENABLE_DRIVER_ACCESS_CODES = 'enableDriverAccessCodes';
export const ENABLE_NEGATIVE_INVENTORY = 'enableNegativeInventory';
export const ENABLE_EDITING_APPROVED_SALES_ORDER = 'enableEditingApprovedSalesOrders';
export const PRODUCT_PRICE_INCLUSIVE_OF_SALES_TAX = 'productPriceInclusiveOfSalesTax';
export const ENABLE_SUGGESTED_LOAD_ORDERS = 'enableSuggestedLoadOrders';
export const ENABLE_CLIENT_PORTAL = 'enableClientPortal';
export const ENABLE_SALE_ORDER_PRICE_ADJUSTING = 'enableSaleOrderPricingAdjusting';
export const ENABLE_SAMPLES = 'enableSamples';
export const ENABLE_BATCH_INFO = 'enableBatchInfo';
export const ENABLE_PRODUCT_ADL = 'enableProductADL';
export const ENABLE_ROUTE_PLANNER_READ_ONLY = 'makeRoutePlannerReadOnlyForUsers';
export const SHOW_DELIVERY_POINT_NAME_IN_ROUTE_PLANNER = 'showDeliveryPointNameInRoutePlanning';
export const SHOW_DELIVERY_POINT_ADDRESS_IN_ROUTE_PLANNER = 'showDeliveryPointAddressInRoutePlanning';
export const SHOW_PRICE_IN_ROUTE_PLANNER = 'showPriceInRoutePlanning';
export const SHOW_PACKAGE_COUNT_IN_ROUTE_PLANNER = 'showPackageCountInRoutePlanning';
export const SHOW_ORDER_WEIGHT_IN_ROUTE_PLANNER = 'showOrderWeightInRoutePlanning';
export const SHOW_ORDER_VOLUME_IN_ROUTE_PLANNER = 'showOrderVolumeInRoutePlanning';
export const SHOW_ORDER_CREATED_TIME_IN_ROUTE_PLANNER = 'showOrderCreatedTimeInRoutePlanning';
export const ENABLE_INVENTORY_MANAGEMENT = 'enableInventoryManagement';
export const ENABLE_MEAN_WEIGHT_AND_TOLERANCE = 'enableMeanWeightAndTolerance';
export const ENABLE_OUT_OF_RANGE_PICK_WEIGHT = 'enableOutOfBoundsWeight';
export const ENABLE_SURVEYS = 'enableSurveys';
export const ENABLE_COMPANY_LEVEL_SURVEYS = 'enableCompanyLevelSurveys';
export const ENABLE_DELIVERY_POINT_SURVEYS = 'enableDeliveryPointLevelSurveys';
export const ENABLE_HANDHELD_PROFILE_LEVEL_SURVEYS = 'enableHandheldLevelSurveys';
export const ENABLE_RETURN_PRICING = 'enableReturnPricing';
export const ENABLE_PLANOGRAM = 'enablePlanogram';
export const ENABLE_DRIVER_TARGETS = 'enableDriverTargets';
export const ENABLE_GAMIFICATION = 'enableGamification';
export const ENABLE_TIME_AT_SITE = 'enableTimeAtSite';
export const ENABLE_DAILY_DELIVERY_POINT_SEQUENCES = 'enableDailyDeliveryPointSequences';
export const ALLOW_CUSTOMER_SPECIFIC_DISCOUNT = 'allowCustomerSpecificDiscount';
export const ENABLE_FRANCHISE_SUPPORT = 'enableFranchiseSupport';
export const ENABLE_VOLUME_BASED_REBATE = 'enableVolumeBasedRebate';
export const VOLUME_BASED_REBATE_PERIOD_START_DATE = 'volumeBasedRebatePeriodStartDate';
export const VOLUME_BASED_REBATE_PERIOD_END_DATE = 'volumeBasedRebatePeriodEndDate';
export const ENABLE_WEIGHT_CONSTRAINTS_FOR_VEHICLES = 'enableVehicleWeightConstraints';
export const ENABLE_MULTI_UOM_CASE = 'enableMultiUomCase';
export const ENABLE_VOLUME_CONSTRAINTS_FOR_VEHICLES = 'enableVehicleVolumeConstraints';
export const ENABLE_THREE_DECIMAL_PLACES = 'enableThreeDecimalPlaces';
export const ENABLE_REAL_TIME_DISPATCH = 'enableRealTimeDispatch';
export const ENABLE_PROSPECT_MANAGEMENT = 'enableProspectManagement';
export const ENABLE_PRODUCT_IMEI_TRACKING = 'enableProductImeiTracking';
export const AUTO_REFRESH_ROUTE_PLANNER_AFTER_X_MINUTES = 'autoRefreshRoutePlannerAfterXMinutes';
export const SHOW_LAST_X_HOURS_COMPLETED_SITE_VISITS_ON_ROUTE_MONITOR = 'showLastXHoursCompletedSiteVisitsOnRouteMonitor';
export const DISABLE_ANALYTICS_TAB = 'disableAnalyticsTab';
export const DISABLE_TASK_MANAGEMENT_TAB = 'disableTaskManagementTab';
export const DISABLE_FILE_MANAGEMENT_TAB = 'disableFileManagementTab';
export const COMPANY_MANAGEMENT_ID = 'company-management';
export const PRODUCT_MANAGEMENT_ID = 'product-main';
export const ANALYTICS_LINK = 'analyticsLink';
export const ENABLE_DUPLICATION_OF_SERVICE_ORDERS = 'allowDuplicateServiceOrders';
export const ENABLE_QUOTE_MANAGEMENT = 'enableQuoteManagement';
export const ETA_CALCULATION_BY_USING = 'etaCalculationByUsing';
export const CUSTOMER_SPECIFIC_PRICES_FOR_DEFAULT_PRODUCTS = 'customerSpecificPricesForDefaultProducts';
export const ENABLE_BRANCH_SPECIFIC_DRIVER = 'enableBranchSpecificDriver';
export const ENABLE_BLOCKING_MESSAGES = 'enableBlockingMessages';
export const ENABLE_STOCK_CHANGE = 'enableStockChange';
export const ENABLE_ADDING_MULTIPLE_BARCODES = 'allowMultipleBarcodesForProduct';
export const ENABLE_EXCISE_TAX = 'enableExciseTax';
export const SUPPORT_VOLUME_BASED_PRICING = 'supportVolumeBasedPricing';
export const ENABLE_AUTOMATIC_SERVICE_DURATION = 'enableAutomaticServiceDuration';
export const ENABLE_CASH_BY_NOMINATION = 'enableCashByNomination';
export const ORDER_ITEMS_BY = 'appOrderItemsBy';
export const ENABLE_BANK_PAYMENT = 'enableBankPayment';
export const NUMBER_OF_BANK_PAYMENTS = 'numberOfBankPayments';
export const ENABLE_CHEQUE_PAYMENT = 'enableBankPayment';
export const NUMBER_OF_CHEQUE_PAYMENTS = 'numberOfBankPayments';
export const ENABLE_LINE_LEVEL_NOTES = 'enableLineLevelNotesForSaleOrder';
export const RETAIN_PREVIOUS_SCREEN_FILTERS = 'retainPreviousScreenFilters';
export const ENABLE_ZERO_VAT_FOR_CERTAIN_CUSTOMER = 'enableZeroVatForCertainCustomer';
export const ENABLE_TRAFFIC_LIGHT_SLA = 'enableTrafficLightSLA';
export const DEFAULT_VAT_FOR_PRODUCTS = 'defaultVATForProducts';
export const ENABLE_CANCEL_INVOICE = 'enableCancelInvoice';
export const ENABLE_ADD_INVOICE = 'enableAddInvoice';
export const ENABLE_INVOICE_NUMBER_ON_ROUTE_PLANNER = 'enableInvoiceNumberOnRoutePlanner';
export const SHIPPING_COST_ENABLED = 'enableShippingCost';
export const DISABLE_AUTO_PAYMENT_AMOUNT_FILLING = 'disableAutoAmountFillPaymentToInvoice';
export const ALLOW_ADDING_DUPLICATE_PRODUCTS = 'allowAddingDuplicateProducts';
export const ENABLE_CANCEL_PAYMENT = 'enableCancelPayment';
export const ENABLE_APPROVE_CREDIT_NOTE_WITH_INVENTORY_ADJUSTMENT = 'enableApproveCreditNoteWithInventoryAdjustment';
export const SHOW_ZERO_QUANTITY_PRODUCTS = 'showProductsWithZeroQuantityOnInvoicePdf';
export const ENABLE_SERVICE_ORDER_ON_SALE_ORDER_PAGE = 'enableServiceOrderOnSaleOrderPage';
export const ENABLE_CLEARING_SMALL_BALANCES_FOR_INVOICES = 'enableClearingSmallBalanceForInvoice';
export const ENABLE_CLEARING_SMALL_UNALLOCATED_AMOUNTS_FOR_PAYMENTS = 'enableClearingSmallUnallocatedAmtForPayment';
export const MAX_BALANCE_TO_BE_CLEARED_FOR_INVOICE = 'maxBalanceAmountToBeClearedForInvoices';
export const MAX_UNALLOCATED_AMOUNT_TO_CLEARED_FOR_PAYMENT = 'maxUnAllocatedAmountToBeClearedForPayments';
export const HIDE_PRICING_INFO_ON_ORDERS = 'hidePricingInfoOnOrders';
export const DISPLAY_ALL_ROUTES_ON_ROUTE_MONITOR_AND_PLANNER = 'displayAllRoutesOnRouteMonitorAndPlanner';
export const ENABLE_PAYMENTS_AT_CUSTOMER_GROUP_LEVEL = 'enablePaymentsAtCustomerGroupLevel';
export const SET_DEFAULT_PICK_QTY_IN_PICK_ORDER_TO_ZERO = 'setDefaultPickQtyInPickOrderToZero';
export const ENABLE_RESTRICTION_FOR_INSUFFICIENT_STOCK = 'enableRestrictionForInsufficientStock';
export const SHOW_DEFAULT_PRICE_ON_PURCHASE_ORDER = 'showDefaultPriceOnPurchaseOrder';
export const SHOW_PRODUCT_COST_ON_ORDER_CREATION = 'showProductCostOnOrderCreation';
export const ENABLE_DUPLICATION_OF_INVOICES = 'enableDuplicationOfInvoices';
export const DEFAULT_QUANTITY_FOR_SALE_ORDER_ITEM = 'defaultQuantityForSaleOrderItem';
export const ENABLE_SAGE_CONFIG = 'enableSageConfig';
export const SHOW_DRIVER_STATUS_ON_ROUTE_PLANNER = 'showDriverStatusOnRoutePlanner';
export const ENABLE_INVENTORY_CHECKS_ON_PICK_ORDER_CONFIRM = 'enableInventoryChecksOnPickOrderConfirm';
export const DISABLE_DISCOUNT_ON_INVOICE_AND_SALE_ORDER = 'disableDiscountsOnInvoiceAndSaleOrder';
export const ENABLE_CURRENCY_AT_CUSTOMER_LEVEL = 'enableCurrencyAtCustomerLevel';
export const ENABLE_WAREHOUSE_TRANSFER_REQUIRES_DISPATCHED = 'enableWarehouseTransferRequiresDispatched';
export const SHOW_ALL_SITE_VISIT_ORDER_DETAILS_ON_POD_PDF = 'showAllSiteVisitOrderDetailsOnPodPdf';
export const ENABLE_EDITING_FOR_STOCK_TAKE = 'enableStockTakeEdit';
export const ENABLE_CUSTOMER_ACCOUNT_TYPE = 'enableCustomerAccountType';
export const HIDE_UNIT_PRICE_ON_RECEIVE_ADJUST_INVENTORY_SCREEN = 'hideUnitPriceOnReceiveAdjustInventoryScreen';
export const NUMBER_OF_DECIMAL_PLACES = 'numberOfDecimalPlaces';
export const NUMBER_OF_DECIMAL_PLACES_FOR_ORDER_SUMMARY_AND_PDF = 'numberOfDecimalPlacesForOrderSummaryAndPdf';
export const DISABLE_DISCOUNTS_ON_RECCEIVE_ADJUST_INVENTORY = 'disableDiscountsOnReceiveAdjustInventory';
export const ENABLE_DUPLICATION_OF_SALE_ORDERS = 'enableDuplicationOfSaleOrders';
export const ENABLE_SHIPPER_QUANTITY_FOR_PRODUCT = 'enableShipperQuantityForProduct';
export const SHOW_ONLY_BRANCH_SPECIFIC_VEHICLES_ON_ROUTE_PLANNER = 'showOnlyBranchSpecificVehiclesOnRoutePlanner';
export const SHOW_NOTES_AS_TEXT_BOX_ON_SALE_ORDER = 'showNotesAsTextBoxOnSaleOrder';
export const SHOW_ROUTE_HAVING_SITE_VISITS_ON_ROUTE_PLANNER = 'showRoutesHavingSiteVisitsOnRoutePlanner';
export const SHOW_INACTIVE_CUSTOMER_ON_MOBILE_APP_AND_ERP = 'appShowInactiveCustomersOnMobileApp';
export const SHOW_PURCHASE_ORDER_MANDATORY_FOR_INCOMING_INVENTORY = 'enablePOMandatoryForIncomingInventory';
export const DISABLE_ADD_PRODUCT_ON_INCOMING_INVENTORY = 'disableAddProductOnIncomingInventory';
export const DEFAULT_PREFERRED_PAYMENT_TYPE_FOR_CUSTOMER = 'defaultPreferredPaymentTypeForCustomer';
export const ENABLE_OVERRIDE_ORDER_BY_ITEMS_FOR_LOAD_ORDER_SCREEN_ON_APP = 'enableOverrideOrderByItemsForLoadOrderScreenOnApp';
export const ENABLE_PRODUCT_ATTRIBUTES = 'enableProductAttributes';
export const ENABLE_OVERRIDE_ORDER_BY_ITEMS_FOR_PICK_ORDER_SCREEN = 'enableOverrideOrderByItemsForPickOrderScreen';
export const DEFAULT_SALE_ORDER_TYPE = 'defaultSaleOrderTypeToForwardSales';
export const ENABLE_WAREHOUSE_BIN_LOCATION_INVENTORY_MANAGEMENT = 'enableWarehouseBinLocationInventoryManagement';
export const STOP_SALE_ORDER_APPROVAL_IF_ORDER_TOTAL_EXCEEDS_CUSTOMER_CREDIT_LIMIT = 'stopSaleOrderApprovalIfOrderTotalExceedsCustomerCreditLimit';
export const SHOW_MRP_ON_SALES_ORDER_SCREEN = 'showMrpOnSalesOrderScreen';
export const SHOW_HSN_CODE_ON_SALES_ORDER_SCREEN = 'showHsnCodeOnSalesOrderScreen';
export const SHOW_MARGIN_PERCENTAGE_ON_SALES_ORDER_SCREEN = 'showMarginPercentageOnSalesOrderScreen';
export const SHOW_RACK_NO_ON_SALES_ORDER_SCREEN = 'showRackNoOnSalesOrderScreen';
export const SHOW_PACKING_ON_SALES_ORDER_SCREEN = 'showPackingOnSalesOrderScreen';
export const SHOW_CATEGORY_ON_SALES_ORDER_SCREEN = 'showCategoryOnSalesOrderScreen';
export const SHOW_MRP_ON_INCOMING_INVENTORY_SCREEN = 'showMrpOnIncomingInventoryScreen';
export const SHOW_HSN_CODE_ON_INCOMING_INVENTORY_SCREEN = 'showHsnCodeOnIncomingInventoryScreen';
export const SHOW_MANUFACTURER_ON_INVENTORY_INVENTORY_SCREEN = 'showManufacturerOnIncomingInventoryScreen';
export const SHOW_RACK_NO_ON_INCOMING_INVENTORY_SCREEN = 'showRackNoOnIncomingInventoryScreen';
export const SHOW_PACKING_ON_INCOMING_INVENTORY_SCREEN = 'showPackingOnIncomingInventoryScreen';
export const SHOW_PRT_ON_INCOMING_INVENTORY_SCREEN = 'showPtrOnIncomingInventoryScreen';
export const ENABLE_ADVANCED_BATCH_INFO = 'enableAdvancedBatchInfo';
export const ENABLE_PAYMENT_THROUGH_PAYMENT_GATEWAY = 'enablePaymentThroughPaymentGateway';
export const SELECT_PAYMENT_GATEWAY_TYPE = 'selectPaymentGatewayType';
export const ENABLE_ONDC = 'enableOndc';
export const SHOW_PRODUCT_LIST_AS_PER_SUPPLIER_FOR_PO = 'showProductListAsPerSupplierForPo';
export const MAKE_BATCH_AND_BIN_NON_MANDATORY_ON_SALE_ORDER = 'makeBatchAndBinNonMandatoryOnSaleOrder';
export const STOP_PRODUCTION_VOUCHER_APPROVAL_IF_INSUFFICIENT_INVENTORY = 'stopProductionVoucherApprovalIfInsufficientInventory';
export const SET_CONSIGNMENT_ORDER_DEFAULT_COUNTED_QTY_AS_START_QTY = 'setConsignmentOrderDefaultCountedQtyAsStartQty';
export const INVERT_SELECTION_AND_INFO_ON_ROUTE_PLANNER = 'invertSelectionAndInfoOnRoutePlanner';
export const SALE_ORDER_CUT_OFF_TIME = 'saleOrderCutOffTime';
export const CACHE_ENABLED = 'cacheEnabled';
