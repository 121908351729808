import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {IBranch} from 'app/blocks/model/branch.model';
import {IProductGroup} from 'app/blocks/model/product-group.model';
import {IRouteMapSymbol} from 'app/blocks/model/route-map-symbol.model';
import {IRouteType} from 'app/blocks/model/route-type.model';
import {IVehicle} from 'app/blocks/model/vehicle.model';
import {IWarehouse} from 'app/blocks/model/warehouse.model';
import {DiTime} from 'app/blocks/util/di-time';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IAddress} from 'app/blocks/model/address.model';

export interface IRoute extends IBaseFranchiseEntity {
    id?: number;
    name?: string;
    description?: string;
    routeStartTime?: any;
    routeEndTime?: any;
    averageServiceTimeInMins?: number;
    driverMessage?: string;
    defaultVehicle?: IVehicle;
    warehouse?: IWarehouse;
    liveTrackingSymbol?: IRouteMapSymbol;
    deliveryPointSymbol?: IRouteMapSymbol;
    servicedStopSymbol?: IRouteMapSymbol;
    plannedStopSymbol?: IRouteMapSymbol;
    routeBreadcrumbSymbol?: IRouteMapSymbol;
    homebranch?: IBranch;
    type?: IRouteType;
    hhProductGroups?: IProductGroup[];
    partnerCommissionPercentage?: number;
    creditCardTerminalLink?: string;
    bankingLink?: string;
    disableRouteOptimization?: boolean;
    enableDriverMessage?: boolean;
    color?: string;
    startAddress?: IAddress;
    endAddress?: IAddress;
    paymentNominalCode?: string;
}
export class Route extends BaseFranchiseEntity implements IRoute {
    public id?: number;
    public name?: string;
    public description?: string;
    public routeStartTime?: any;
    public routeEndTime?: any;
    public averageServiceTimeInMins?: number;
    public driverMessage?: string;
    public defaultVehicle?: IVehicle;
    public warehouse?: IWarehouse;
    public liveTrackingSymbol?: IRouteMapSymbol;
    public deliveryPointSymbol?: IRouteMapSymbol;
    public servicedStopSymbol?: IRouteMapSymbol;
    public plannedStopSymbol?: IRouteMapSymbol;
    public routeBreadcrumbSymbol?: IRouteMapSymbol;
    public homebranch?: IBranch;
    public type?: IRouteType;
    public hhProductGroups?: IProductGroup[];
    public partnerCommissionPercentage?: number;
    public creditCardTerminalLink?: string;
    public bankingLink?: string;
    public disableRouteOptimization?: boolean;
    public enableDriverMessage?: boolean;
    public color?: string;
    public startAddress?: IAddress;
    public endAddress?: IAddress;
    public paymentNominalCode?: string;

    constructor(route?) {
        super();
        if (route) {
            this.id = route.id;
            this.name = route.name;
            this.description = route.description;
            this.routeStartTime = DiTime.newInstance(route.routeStartTime);
            this.routeEndTime = DiTime.newInstance(route.routeEndTime);
            this.averageServiceTimeInMins = route.averageServiceTimeInMins;
            this.driverMessage = route.driverMessage;
            this.defaultVehicle = route.defaultVehicle;
            this.warehouse = route.warehouse;
            this.liveTrackingSymbol = route.liveTrackingSymbol;
            this.deliveryPointSymbol = route.deliveryPointSymbol;
            this.servicedStopSymbol = route.servicedStopSymbol;
            this.plannedStopSymbol = route.plannedStopSymbol;
            this.routeBreadcrumbSymbol = route.routeBreadcrumbSymbol;
            this.homebranch = route.homebranch;
            this.type = route.type;
            this.hhProductGroups = route.hhProductGroups || [];
            this.partnerCommissionPercentage = route.partnerCommissionPercentage;
            this.creditCardTerminalLink = route.creditCardTerminalLink;
            this.bankingLink = route.bankingLink;
            this.editable = route.editable;
            this.disableRouteOptimization = route.disableRouteOptimization || false;
            this.enableDriverMessage = route.enableDriverMessage || false;
            this.color = route.color;
            this.startAddress = route.startAddress;
            this.endAddress = route.endAddress;
            this.paymentNominalCode = route.paymentNominalCode;
        } else {
            this.hhProductGroups = [];
            this.disableRouteOptimization = false;
            this.enableDriverMessage = false;
        }
    }
    get discriminator(): any {
        return this.name;
    }
    clone(): Route {
        return new Route(this);
    }
}
