import {ICreditTerm} from 'app/blocks/model/credit-term.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IBank} from 'app/blocks/model/bank.model';
import {IContactInfo} from 'app/blocks/model/contact-info.model';
import {IPaymentType} from 'app/blocks/model/payment-type.model';
import {ISupplierGroup} from 'app/blocks/model/supplier-group.model';
import {IEmployee} from 'app/blocks/model/employee.model';
export interface ISupplier extends IBaseEntity {
    id?: number;
    name?: string;
    contactInfo?: IContactInfo;
    bankingInfo?: IBank;
    code?: string;
    paymentType?: IPaymentType;
    creditType?: ICreditTerm;
    secondaryAccountNo?: string;
    externalAccountReference?: string;
    trn?: string;
    vatNotApplicable?: boolean;
    supplierPaymentTerm?: any;
    supplierGroups?: ISupplierGroup[];
    inactive?: boolean;
    purchaser?: IEmployee;
    balance?: number;
}
export class Supplier extends BaseEntity implements ISupplier {
    public id?: number;
    public name?: string;
    public code?: string;
    public contactInfo?: IContactInfo;
    public bankingInfo?: IBank;
    public paymentType?: IPaymentType;
    public creditType?: ICreditTerm;
    public secondaryAccountNo?: string;
    public externalAccountReference?: string;
    public trn?: string;
    public vatNotApplicable?: boolean;
    public supplierPaymentTerm?: any;
    public supplierGroups?: ISupplierGroup[];
    public inactive?: boolean;
    public purchaser?: IEmployee;
    public balance?: number;
    constructor(supplier?) {
        super();
        if (supplier) {
            this.id = supplier.id;
            this.name = supplier.name;
            this.contactInfo = supplier.contactInfo;
            this.bankingInfo = supplier.bankingInfo;
            this.code = supplier.code;
            this.paymentType = supplier.paymentType;
            this.creditType = supplier.creditType;
            this.secondaryAccountNo = supplier.secondaryAccountNo;
            this.externalAccountReference = supplier.externalAccountReference;
            this.trn = supplier.trn;
            this.vatNotApplicable = supplier.vatNotApplicable;
            this.supplierPaymentTerm = supplier.supplierPaymentTerm;
            this.supplierGroups = supplier.supplierGroups;
            this.inactive = supplier.inactive;
            this.purchaser = supplier.purchaser;
            this.balance = supplier.balance;
        }
    }
    get discriminator(): any {
        return this.name;
    }
    clone(): Supplier {
        return new Supplier(this);
    }
}
