import {ISupplier} from 'app/blocks/model/supplier.model';
import {IWarehouse} from 'app/blocks/model/warehouse.model';

import {DiTime} from 'app/blocks/util/di-time';
import {MposDate} from 'app/blocks/util/mpos-date';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ICustomer} from 'app/blocks/model/customer.model';
import {IDeliveryMethod} from 'app/blocks/model/delivery-method.model';
import {Discount, IDiscount} from 'app/blocks/model/discount.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {Mposfile} from 'app/blocks/model/mpos-file.model';
import {INote} from 'app/blocks/model/note.model';
import {IOrderItem} from 'app/blocks/model/order-item.model';
import {IOrderPriority} from 'app/blocks/model/order-priority.model';
import {ITimeWindow} from 'app/blocks/model/time-window.model';
import {Note} from 'app/blocks/model/note.model';
import {PurchaseOrderStatus} from 'app/blocks/model/purchase-order-status';
import {IPurchaseOrderItem} from 'app/blocks/model/purchase-order-item.model';
import {IPaymentType} from 'app/blocks/model/payment-type.model';
import {ICreditTerm} from 'app/blocks/model/credit-term.model';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IAdHocLine} from 'app/blocks/model/ad-hoc-line';

export interface IPurchaseOrder extends IBaseFranchiseEntity {
    id?: number;
    purchaseOrderNo?: string;
    sourceDocumentNo?: string;
    requestDate?: any;
    requestTime?: any;
    scheduledDate?: any;
    scheduledTime?: any;
    approved?: boolean;
    discount?: IDiscount;
    tax?: number;
    subTotal?: number;
    total?: number;
    type?: any;
    priority?: IOrderPriority;
    requestedTimeWindows?: ITimeWindow[];
    orderItems?: IOrderItem[];
    requestTakenBy?: IEmployee;
    purchaseOrderStatus?: PurchaseOrderStatus;
    orderDocuments?: Mposfile[];
    notes?: INote;
    internalNotes?: INote;
    warehouse?: IWarehouse;
    supplier?: ISupplier;
    paymentType?: IPaymentType;
    creditType?: ICreditTerm;
    deliveredItems: any;
    dueDate?: any;
    underDispute?: boolean;
    underDisputeNote?: string;
    adHocLineItems?: IAdHocLine[];
    approveOnCreateOrUpdate?: boolean;
    probability?: number;
}

export class PurchaseOrder extends BaseFranchiseEntity implements IPurchaseOrder {
    public id?: number;
    public purchaseOrderNo?: string;
    public sourceDocumentNo?: string;
    public requestDate?: any;
    public requestTime?: any;
    public scheduledDate?: any;
    public scheduledTime?: any;
    public approved?: boolean;
    public isBulkPick?: boolean;
    public discount?: IDiscount;
    public tax?: number;
    public subTotal?: number;
    public total?: number;
    public type?: any;
    public priority?: IOrderPriority;
    public requestedTimeWindows?: ITimeWindow[];
    public orderItems?: IPurchaseOrderItem[];
    public requestTakenBy?: IEmployee;
    public purchaseOrderStatus?: PurchaseOrderStatus;
    public orderDocuments?: Mposfile[];
    public notes?: INote;
    public internalNotes?: INote;
    public warehouse?: IWarehouse;
    public supplier?: ISupplier;
    public paymentType?: IPaymentType;
    public creditType?: ICreditTerm;
    public deliveredItems: any;
    public dueDate: any;
    public underDispute?: boolean;
    public underDisputeNote?: string;
    public adHocLineItems?: IAdHocLine[];
    public approveOnCreateOrUpdate?: boolean;
    public probability?: number;

    constructor(purchaseOrder?) {
        super();
        if (purchaseOrder) {
            this.id = purchaseOrder.id;
            this.purchaseOrderNo = purchaseOrder.purchaseOrderNo;
            this.sourceDocumentNo = purchaseOrder.sourceDocumentNo;
            this.requestDate = MposDate.newInstance(purchaseOrder.requestDate);
            this.requestTime = DiTime.newInstance(purchaseOrder.requestTime);
            this.scheduledDate = MposDate.newInstance(purchaseOrder.scheduledDate);
            this.scheduledTime = DiTime.newInstance(purchaseOrder.scheduledTime);
            this.approved = purchaseOrder.approved;
            this.isBulkPick = purchaseOrder.isBulkPick;
            this.discount = purchaseOrder.discount || {
                id: -1,
                discount: 0,
                value: 0,
                discountType: 'ABSOLUTE'
            };
            this.tax = purchaseOrder.tax;
            this.subTotal = purchaseOrder.subTotal;
            this.total = purchaseOrder.total;
            this.type = purchaseOrder.type;
            this.priority = purchaseOrder.priority;
            this.requestedTimeWindows = purchaseOrder.requestedTimeWindows || [];
            this.orderItems = purchaseOrder.orderItems || [];
            this.requestTakenBy = purchaseOrder.requestTakenBy;
            this.purchaseOrderStatus = purchaseOrder.purchaseOrderStatus;
            this.orderDocuments = purchaseOrder.orderDocuments || [];
            this.notes = purchaseOrder.notes || new Note();
            this.internalNotes = purchaseOrder.internalNotes || new Note();
            this.warehouse = purchaseOrder.warehouse;
            this.supplier = purchaseOrder.supplier;
            this.paymentType = purchaseOrder.paymentType;
            this.creditType = purchaseOrder.creditType;
            this.editable = purchaseOrder.editable;
            this.deliveredItems = purchaseOrder.deliveredItems;
            this.dueDate = MposDate.newInstance(purchaseOrder.dueDate);
            this.underDispute = purchaseOrder.underDispute;
            this.underDisputeNote = purchaseOrder.underDisputeNote;
            this.adHocLineItems = purchaseOrder.adHocLineItems;
            this.approveOnCreateOrUpdate = purchaseOrder.approveOnCreateOrUpdate;
            this.probability = purchaseOrder.probability;
        } else {
            this.requestedTimeWindows = [];
            this.orderItems = [];
            this.requestDate = MposDate.currentDate();
            this.scheduledDate = MposDate.currentDate();
            this.requestTime = DiTime.currentTime();
            this.purchaseOrderStatus = PurchaseOrderStatus.PENDING;
            this.discount = new Discount({
                id: -1,
                discount: 0,
                value: 0,
                discountType: 'ABSOLUTE'
            });
            this.notes = new Note();
            this.internalNotes = new Note();
            this.adHocLineItems = [];
        }
    }
    get discriminator(): any {
        return this.purchaseOrderNo;
    }
    clone(): PurchaseOrder {
        return new PurchaseOrder(this);
    }
}
