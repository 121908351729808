import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IContactInfo, ContactInfo} from 'app/blocks/model/contact-info.model';
import {IBranch} from 'app/blocks/model/branch.model';
import {IEmployeeGroup} from 'app/blocks/model/employee-group.model';
import {IHandheldProfile} from 'app/blocks/model/handheld-profile.model';
import {ITarget} from 'app/blocks/model/target.model';
import {IFranchise} from 'app/blocks/model/franchise.model';

export interface IEmployee extends IBaseEntity {
    id?: number;
    employeeId?: string;
    initials?: string;
    inactive?: boolean;
    contactInfo?: IContactInfo;
    homeBranch?: IBranch;
    groups?: IEmployeeGroup[];
    belongingBranches?: IBranch[];
    firstName?: string;
    lastName?: string;
    email?: string;
    authorities?: any;
    roles?: any;
    activated?: boolean;
    login?: string;
    password?: string;
    confirmPassword?: string;
    user?: any;
    handheldProfile?: IHandheldProfile;
    name?: string;
    driverBalance?: any;
    partnerCredit?: number;
    targetType?: string;
    targetValue?: number;
    franchise?: IFranchise;
    mfaKey?: string;
    isMfaEnabled?: boolean;
    franchiseCode?: any;
}

export class Employee extends BaseEntity implements IEmployee {
    public id?: number;
    public employeeId?: string;
    public initials?: string;
    public inactive?: boolean;
    public contactInfo?: ContactInfo;
    public homeBranch?: IBranch;
    public groups?: IEmployeeGroup[];
    public belongingBranches?: IBranch[];
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public authorities?: any;
    public roles?: any;
    public activated?: boolean;
    public login?: string;
    public password?: string;
    public confirmPassword?: string;
    public user?: any;
    public handheldProfile?: IHandheldProfile;
    public name?: string;
    public driverBalance?: any;
    public partnerCredit?: number;
    public targetType?: string;
    public targetValue?: number;
    public franchise?: IFranchise;
    public mfaKey?: string;
    public isMfaEnabled?: boolean;
    public franchiseCode?: any;

    constructor(employee?) {
        super();
        if (employee) {
            this.id = employee.id;
            this.employeeId = employee.employeeId;
            this.initials = employee.initials;
            this.inactive = employee.inactive;
            this.contactInfo = employee.contactInfo;
            this.homeBranch = employee.homeBranch;
            this.groups = employee.groups || [];
            this.belongingBranches = employee.belongingBranches || [];
            this.firstName = employee.firstName;
            this.lastName = employee.lastName;
            this.email = employee.email;
            this.authorities = employee.authorities || [];
            this.roles = employee.roles || [];
            this.activated = employee.activated || true;
            this.login = employee.login;
            this.password = employee.password;
            this.user = employee.user;
            this.handheldProfile = employee.handheldProfile;
            this.name = employee.name;
            this.partnerCredit = employee.partnerCredit;
            this.driverBalance = employee.driverBalance;
            this.targetType = employee.targetType;
            this.targetValue = employee.targetValue;
            this.franchise = employee.franchise;
            this.mfaKey = employee.mfaKey;
            this.isMfaEnabled = employee.isMfaEnabled;
            if (employee.franchise) {
                this.franchiseCode = employee.franchise.code;
            }
        } else {
            this.groups = [];
            this.belongingBranches = [];
            this.authorities = ['ROLE_USER'];
            this.roles = ['ROLE_USER'];
            this.contactInfo = new ContactInfo();
        }
    }

    get discriminator(): any {
        return this.employeeId;
    }

    clone(): Employee {
        return new Employee(this);
    }
}
