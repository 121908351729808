import {Employee} from 'app/blocks/model/employee.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IEmployee} from 'app/blocks/model/employee.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {createRequestOption} from 'app/blocks/util/request-util';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService extends EntityCrudService<IEmployee> {
    constructor(http: HttpClient) {
        super(http, Resource.EMPLOYEES, (obj?) => new Employee(obj));
    }

    public findEmployeesWithRoles = (role: string, params: any): Promise<HttpResponse<any>> => {
        const requestParams = createRequestOption(params);
        const REQUEST_URI = `${SERVER_API_URL}api/employees/roles/${role}`;
        return this.http.get(REQUEST_URI, {params: requestParams, observe: 'response'}).toPromise();
    };

    getVehiclesForHomeBranch(employeeId: number, params: any): Promise<HttpResponse<any>> {
        const requestParams = createRequestOption(params);
        const REQUEST_URI = `${SERVER_API_URL}api/employees/${employeeId}/home-branch-vehicles`;
        return this.http.get(REQUEST_URI, {params: requestParams, observe: 'response'}).toPromise();
    }

    getHomeBranchForEmployee(employeeId: number): Promise<HttpResponse<any>> {
        const REQUEST_URI = `${SERVER_API_URL}api/employees/${employeeId}/home-branch`;
        return this.http.get(REQUEST_URI, {params: null, observe: 'response'}).toPromise();
    }
}
