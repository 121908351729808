import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';

export interface IDiscount extends IBaseEntity {
    discount?: number;
    value?: number;
    discountType?: string;
}

export class Discount extends BaseEntity implements IDiscount {
    public id: number;
    public discount?: number;
    public value?: number;
    public discountType?: string;

    constructor(discount?) {
        super();
        if (discount) {
            this.id = discount.id;
            this.discount = discount.discount;
            this.value = discount.value;
            this.discountType = discount.discountType;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Discount {
        return new Discount(this);
    }
}
