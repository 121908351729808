import {DeliveryPoint} from 'app/blocks/model/delivery-point.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IDeliveryPoint} from 'app/blocks/model/delivery-point.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';
import {IDeliveryPointSetting} from 'app/blocks/model/delivery-point-setting.model';

@Injectable({
    providedIn: 'root'
})
export class DeliveryPointService extends EntityCrudService<IDeliveryPoint> {
    constructor(http: HttpClient) {
        super(http, Resource.DELIVERY_POINTS, (obj?) => new DeliveryPoint(obj));
    }

    searchDeliveryPointsByText = (pageNumber, size, text): Promise<HttpResponse<any>> => {
        const params = {query: text, page: pageNumber, size: size};
        const options = createRequestOption(params);
        return this.http
            .get(`${SERVER_API_URL}api/_search/delivery-points`, {
                observe: 'response',
                params: options
            })
            .toPromise();
    };

    searchActiveDeliveryPointsByText = (pageNumber, size, text, showActive): Promise<HttpResponse<any>> => {
        const params = {query: text, showActive: showActive, page: pageNumber, size: size};
        const options = createRequestOption(params);
        return this.http
            .get(`${SERVER_API_URL}api/_search/delivery-points`, {
                observe: 'response',
                params: options
            })
            .toPromise();
    };

    getWinningChances = (): Promise<any> => {
        return this.http
            .get(`${SERVER_API_URL}api/delivery-points/winning-chances`, {
                observe: 'response'
            })
            .toPromise();
    };

    getPipelineStages = (): Promise<any> => {
        return this.http
            .get(`${SERVER_API_URL}api/delivery-points/pipeline-stages`, {
                observe: 'response'
            })
            .toPromise();
    };

    getDefaultDeliveryPointSettingsForRoute = (routeId: number): Promise<any> => {
        return this.http.get<IDeliveryPointSetting[]>(`${SERVER_API_URL}api/delivery-points/settings/route/${routeId}`).toPromise();
    };

    searchDeliveryPointByText = (pageNumber, size, text, sortBy = 'id,asc'): Promise<HttpResponse<any>> => {
        let params = new HttpParams();
        params = params.append('query', text);
        params = params.append('page', pageNumber);
        params = params.append('size', size);
        params = params.append('sort', sortBy);
        return this.http
            .get(SERVER_API_URL + 'api/_search/delivery-points', {
                observe: 'response',
                params: params
            })
            .toPromise();
    };

    getDepositItemInvoiceHistory = (deliveryPointId, params: any): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/delivery-points/${deliveryPointId}/deposit-items`;
        const requestParams = createRequestOption(params);
        return this.http
            .get(REQUEST_URI, {
                params: requestParams
            })
            .toPromise();
    };

    getNextScheduledDateForDeliveryPointVanSaleSchedule = (deliveryPointId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/delivery-points/${deliveryPointId}/next-van-sale-scheduled-date`;
        return this.http.get(REQUEST_URI).toPromise();
    };

    getDeliveryPointsForProductList = (productListId: number, blackWhiteList: string): Promise<any> => {
        let params = new HttpParams();
        params = params.append('blackWhiteList', blackWhiteList);
        return this.http.get(`${SERVER_API_URL}api/delivery-points/product-list/${productListId}`, {observe: 'response', params: params}).toPromise();
    };
}
