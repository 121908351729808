import {IContactInfo, ContactInfo} from 'app/blocks/model/contact-info.model';
import {ICompany} from 'app/blocks/model/company.model';
import {IWarehouse} from 'app/blocks/model/warehouse.model';
import {DiTime} from 'app/blocks/util/di-time';
import {BaseFranchiseEntity, IBaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';

export interface IBranch extends IBaseFranchiseEntity {
    id?: number;
    code?: string;
    secondaryCode?: string;
    description?: string;
    mainBranch?: boolean;
    vatRegNo?: string;
    defaultRouteStartTime?: any;
    defaultRouteEndTime?: any;
    defaultServiceDurationMins?: number;
    contactInfo?: IContactInfo;
    company?: ICompany;
    warehouses?: IWarehouse[];
}

export class Branch extends BaseFranchiseEntity implements IBranch {
    public id: number;
    public code: string;
    public secondaryCode: string;
    public description: string;
    public mainBranch: boolean;
    public vatRegNo: string;
    public defaultRouteStartTime: any;
    public defaultRouteEndTime: any;
    public defaultServiceDurationMins: number;
    public contactInfo: IContactInfo;
    public company: ICompany;
    public warehouses: IWarehouse[];
    // FIXME: To remove after experimentation
    // public branchDate: any;
    // public branchDate2: any;

    constructor(branch?) {
        super();
        if (branch) {
            this.id = branch.id;
            this.code = branch.code;
            this.secondaryCode = branch.secondaryCode;
            this.description = branch.description;
            this.mainBranch = branch.mainBranch;
            this.vatRegNo = branch.vatRegNo;
            this.defaultRouteStartTime = DiTime.newInstance(branch.defaultRouteStartTime);
            this.defaultRouteEndTime = DiTime.newInstance(branch.defaultRouteEndTime);
            this.defaultServiceDurationMins = branch.defaultServiceDurationMins;
            // this.branchDate = MposDate.newInstance(branch.branchDate);
            // this.branchDate2 = MposDate.newInstance(branch.branchDate2);
            this.contactInfo = new ContactInfo(branch.contactInfo);
            this.editable = branch.editable;
            // this.company = new Company(branch.company);
        } else {
            this.contactInfo = new ContactInfo();
            // this.company = new Company();
        }
    }

    get discriminator(): any {
        return this.code;
    }

    clone(): Branch {
        return new Branch(this);
    }
}
