import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CustomerGroupService} from 'app/blocks/service/api/customer-group.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ICustomerGroup} from 'app/blocks/model/customer-group.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {CustomerGroup} from 'app/blocks/model/customer-group.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class CustomerGroupResource extends AbstractEntityResource<ICustomerGroup> {
    constructor(customerGroupService: CustomerGroupService, router: Router) {
        super(
            Resource.CUSTOMER_GROUPS,
            customerGroupService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.CUSTOMER_GROUPS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.CUSTOMER_GROUPS),
            (entity?) => new CustomerGroup(entity),
            (entity) => entity.name,
            'group_work'
        );
    }
}
