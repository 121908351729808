import {CustomerGroup} from 'app/blocks/model/customer-group.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ICustomerGroup} from 'app/blocks/model/customer-group.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {createRequestOption} from 'app/blocks/util/request-util';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class CustomerGroupService extends EntityCrudService<ICustomerGroup> {
    constructor(http: HttpClient) {
        super(http, Resource.CUSTOMER_GROUPS, (obj?) => new CustomerGroup(obj));
    }

    searchCustomerGroupsByText = (pageNumber, size, text): Promise<HttpResponse<any>> => {
        const params = {query: text, page: pageNumber, size: size};
        const options = createRequestOption(params);
        return this.http
            .get(`${SERVER_API_URL}api/_search/customer-groups`, {
                observe: 'response',
                params: options
            })
            .toPromise();
    };
}
