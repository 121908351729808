import {Route} from 'app/blocks/model/route.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IRoute} from 'app/blocks/model/route.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {Observable} from 'rxjs';
import {StringUtils} from 'app/blocks/util/string-util';
import {StyleUtils} from 'app/blocks/util/style-util';

@Injectable({
    providedIn: 'root'
})
export class RouteService extends EntityCrudService<IRoute> {
    constructor(http: HttpClient) {
        super(http, Resource.ROUTES, (obj?) => new Route(obj));
    }

    getRecentBlockMessagesByRoute(routeId: number): Observable<any> {
        return this.http.get<any>(SERVER_API_URL + 'api/block-messages/' + routeId);
    }

    sendBlockMessage(routeId: number, blockMessage: any): Promise<any> {
        return this.http.post(SERVER_API_URL + 'api/block-messages/' + routeId, blockMessage).toPromise();
    }
}
