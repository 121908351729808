import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BranchService} from 'app/blocks/service/api/branch.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IBranch} from 'app/blocks/model/branch.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Branch} from 'app/blocks/model/branch.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class BranchResource extends AbstractEntityResource<IBranch> {
    constructor(branchService: BranchService, router: Router) {
        super(
            Resource.BRANCHES,
            branchService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.BRANCHES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.BRANCHES),
            (entity?) => new Branch(entity),
            (entity) => entity.code,
            'share'
        );
    }
}
