import {ProductGroup} from 'app/blocks/model/product-group.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ISupplier, Supplier} from 'app/blocks/model/supplier.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {createRequestOption} from 'app/blocks/util/request-util';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class SupplierService extends EntityCrudService<ISupplier> {
    constructor(http: HttpClient) {
        super(http, Resource.SUPPLIERS, (obj?) => new Supplier(obj));
    }

    getRecentOrders = (supplierId: number, numberPreviousOrders: number): Promise<any> => {
        const requestParams = createRequestOption({
            count: numberPreviousOrders
        });
        return this.http
            .get(`${SERVER_API_URL}api/suppliers/${supplierId}/recent-orders`, {
                observe: 'response',
                params: requestParams
            })
            .toPromise();
    };

    getSupplier = (supplierId: any): Promise<HttpResponse<any>> => {
        return this.http
            .get(SERVER_API_URL + 'api/suppliers/' + supplierId, {
                observe: 'response'
            })
            .toPromise();
    };
}
