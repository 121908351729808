import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
export interface IProductGroup extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
}
export class ProductGroup extends BaseEntity implements IProductGroup {
    public id?: number;
    public name?: string;
    public description?: string;
    constructor(productGroup?) {
        super();
        if (productGroup) {
            this.id = productGroup.id;
            this.name = productGroup.name;
            this.description = productGroup.description;
        } else {
        }
    }
    get discriminator(): any {
        return this.name;
    }
    clone(): ProductGroup {
        return new ProductGroup(this);
    }
}
