import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SupplierService} from 'app/blocks/service/api/supplier.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {ISupplier} from 'app/blocks/model/supplier.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Supplier} from 'app/blocks/model/supplier.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class SupplierResource extends AbstractEntityResource<ISupplier> {
    constructor(supplierService: SupplierService, router: Router) {
        super(
            Resource.SUPPLIERS,
            supplierService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.SUPPLIERS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.SUPPLIERS),
            (entity?) => new Supplier(entity),
            (entity) => entity.name,
            'category'
        );
    }
}
