import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RouteService} from 'app/blocks/service/api/route.service';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {IRoute} from 'app/blocks/model/route.model';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {Route} from 'app/blocks/model/route.model';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

@Injectable({
    providedIn: 'root'
})
export class RouteResource extends AbstractEntityResource<IRoute> {
    constructor(routeService: RouteService, router: Router) {
        super(
            Resource.ROUTES,
            routeService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.ROUTES, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.ROUTES),
            (entity?) => new Route(entity),
            (entity) => entity.name,
            'directions'
        );
    }
}
