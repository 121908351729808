import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';
import {MposDate} from 'app/blocks/util/mpos-date';
import {IPurchaseOrder, PurchaseOrder} from 'app/blocks/model/purchase-order.model';

@Injectable({
    providedIn: 'root'
})
export class PurchaseOrderService extends EntityCrudService<IPurchaseOrder> {
    constructor(http: HttpClient) {
        super(http, Resource.PURCHASE_ORDERS, (obj?) => new PurchaseOrder(obj));
    }

    getPricing = (request: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/price', request).toPromise();
    };

    confirmPurchaseOrder = (id): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/purchase-orders/' + id + '/confirm', null, {observe: 'response'}).toPromise();
    };

    cancelPurchaseOrder = (id: number, date: MposDate, time: string): Promise<HttpResponse<any>> => {
        const request = {date, time};
        return this.http.post<any>(SERVER_API_URL + 'api/purchase-orders/' + id + '/cancel', request, {observe: 'response'}).toPromise();
    };

    markAsDelivered = (request): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + 'api/purchase-orders/mark-as-delivered', request, {observe: 'response'}).toPromise();
    };

    receivePurchaseOrder = (id): Promise<HttpResponse<any>> => {
        return this.http.post<any>(SERVER_API_URL + 'api/purchase-orders/' + id + '/receive', null, {observe: 'response'}).toPromise();
    };

    uploadFile = (label: string, file: File, purchaseOrderId: number): Promise<any> => {
        const fd = new FormData();
        fd.append('file', file, file.name);
        fd.append('label', label);
        return this.http.post<any>(`${SERVER_API_URL}api/purchase-orders/${purchaseOrderId}/docs`, fd).toPromise();
    };

    downloadFile = (purchaseOrderId: number, mposFileId: number): any => {
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-orders/${purchaseOrderId}/docs/${mposFileId}`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'});
    };

    deleteFile = (purchaseOrderId: number, mposFileId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-orders/${purchaseOrderId}/docs/${mposFileId}`;
        return this.http.delete(REQUEST_URI).toPromise();
    };

    markAsApproved = (ids: number[]): any => {
        const requestParams = new HttpParams().set('ids', ids.join(','));
        return this.http
            .post<any>(SERVER_API_URL + 'api/purchase-orders/mark-as-approved', null, {observe: 'response', params: requestParams})
            .toPromise();
    };

    getPurchaseOrder = (purchaseOrderId: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-orders/${purchaseOrderId}`;
        return this.http.get(REQUEST_URI).toPromise();
    };

    downloadPDF = (id: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-orders/${id}/pdf`;
        return this.http.get(REQUEST_URI, {responseType: 'arraybuffer'}).toPromise();
    };

    closePurchaseOrder = (id: number): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-orders/${id}/close`;
        return this.http.post(REQUEST_URI, {}).toPromise();
    };
    downloadMergedPickOrders = (ids: number[]): Promise<any> => {
        const requestParams = new HttpParams().set('ids', ids.toString());
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-orders/download`;
        return this.http
            .get(REQUEST_URI, {
                responseType: 'arraybuffer',
                params: requestParams
            })
            .toPromise();
    };

    getPurchaseOrdersByStatuses = (statuses: string[], dueDateGreaterThan: any): Promise<any> => {
        const requestParams = new HttpParams().set('statuses', statuses.join(',')).set('dueDateGreaterThan', dueDateGreaterThan);
        const REQUEST_URI = `${SERVER_API_URL}api/purchase-orders/status`;
        return this.http.get(REQUEST_URI, {params: requestParams}).toPromise();
    };
}
