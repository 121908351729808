import {Branch} from 'app/blocks/model/branch.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IBranch} from 'app/blocks/model/branch.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class BranchService extends EntityCrudService<IBranch> {
    constructor(http: HttpClient) {
        super(http, Resource.BRANCHES, (obj?) => new Branch(obj));
    }

    getBranch = (branchId: any): Promise<any> => {
        return this.http
            .get(`${SERVER_API_URL}api/branches/${branchId}`, {
                observe: 'response'
            })
            .toPromise();
    };
}
